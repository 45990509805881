import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


export default function AlertComponent() {
    const dispatch = useDispatch();
    const alertsObj = useSelector(state => state.alertsHandler);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    React.useEffect(() => {        
        alertsObj.alertsList.forEach(val => {
            // removing alert
            if(val.dismiss) {
                val.dismiss = false;
                closeSnackbar(val.id);
                return;
            }

            enqueueSnackbar(val.msg, {
                key: val.id,
                persist: val.dissolve,
                variant: (val.alertType === undefined) ? 'default' : val.alertType ,
                onExited: (evt, key) => {
                    dispatch({type: 'RM_ALERT', id: key});
                }
            });
        });

    }, [alertsObj, enqueueSnackbar, closeSnackbar, dispatch]);

    return null;
}