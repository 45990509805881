import React, { useEffect, useState } from 'react';
import './blog.css'

import {
    Container,
    Box,
    Card,
    Typography,
    Grid,
    List,
    ListItem,
    IconButton,
    Avatar,
    makeStyles,
    CardActionArea,
    CardContent,
    CircularProgress
} from '@material-ui/core';
import { Link, matchPath, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';

import { fetchEntriesPost, fetchBlogCategories } from '../api';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

const useStyle = makeStyles(theme => ({
    header: {
        margin: theme.spacing(3),
        marginTop: 0,
        padding: theme.spacing(3),
        background: 'rgba(175, 175, 175, 0.1)',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },

    cardEntry: {
        margin: theme.spacing(3),
        marginTop: 0,
        background: 'rgba(200, 200, 200, 0.1)',
    },

    navigationBox: {
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(3)
        }
    },
    
    posts: {
        [theme.breakpoints.down('xs')]: {
            order: 2
        }
    },
    
    categories: {
        [theme.breakpoints.down('xs')]: {
            order: 1,
            margin: theme.spacing(3),
            marginTop: 0,

        }
    },

    topIcons: {
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
        paddingBottom: 0
    }

}));

export default function Blog() {
    const classes = useStyle();
    const [status, setStatus] = useState('LOADING');
    const [content, setContent] = useState([]);
    const [categories, setCategories] = useState([]);

    // after loading
    const location = useLocation();
    useEffect(() => {
        const successFetch = (data) => {
            setStatus('DONE');
            setContent(data);
        };

        let match = matchPath(location.pathname, {path: '/blog/:filter'});
        if(match) {
            fetchEntriesPost(successFetch, match.params.filter);
        } else {
            fetchEntriesPost(successFetch);
        }

        fetchBlogCategories((arr) => {
            setCategories(arr);
        });
    }, [location]);

    return(
        <div className="blogApp">
            <Container>
                <Box className={classes.topIcons}>
                    <IconButton component={Link} to="/blog" aria-label="Home">
                        <HomeIcon fontSize="large" />
                    </IconButton>
                    <Avatar alt="Aemer" component={Link} to="/" src="http://l.raviuz.com/profpic.jpg" style={{margin: 10}}></Avatar>
                </Box>

                <Card variant="outlined" className={classes.header}>
                    <Typography variant="h3">Hello, welcome to the blog!</Typography>
                    
                </Card>

                <Grid container>
                        <Grid item xs={12} sm={8} className={classes.posts}>

                        {
                            status === 'LOADING' &&
                            <Box style={{width: '100%', height: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center'}}><CircularProgress /></Box>
                        }

                        { 
                        content.map((elm) => {
                            return (
                            <Card key={elm.id} variant="outlined" className={classes.cardEntry}>
                                <CardActionArea component={Link} to={"/blog/view/" + elm.id}>
                                    <CardContent>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Typography variant="overline">{moment(elm.date).format("MMM DD, YYYY")}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="overline">{elm.category}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="h6">{elm.title}</Typography>
                                        <Typography noWrap component="div" style={{padding: '0 10px'}}>
                                            { ReactHtmlParser(elm.content, { transform: (node, index) => {
                                                if(node.name === 'p' && index >= 3) {
                                                    return null;
                                                }
                                            }}) }
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            );
                        })
                        }
                            
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.categories}>
                            <Card variant="outlined" className={classes.navigationBox}>
                                <Typography variant="h6">Categories</Typography>
                                <List>
                                    <ListItem button component={Link} to="/blog/" >All</ListItem>
                                    {
                                        categories.map(elm => {
                                            return (<ListItem button key={elm.title} component={Link} to={"/blog/" + elm.title}>{elm.title}</ListItem>)
                                        })
                                    }
                                </List>
                            </Card>
                        </Grid>
                </Grid>

                <Box style={{textAlign: 'center'}}>
                    <Typography>Copyright &copy; Aemer Erana 2020</Typography>
                </Box>
            </Container>
            
        </div>
    );
}