import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { 
  BrowserRouter
} from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducers from './reducers';

import { SnackbarProvider } from 'notistack';
import AlertComponent from './extComponents/AlertsComponent';


// store
const store = createStore(rootReducers);

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={store}>

        <SnackbarProvider 
          maxSnack={3}
          preventDuplicate
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >

        <AlertComponent />
        <App />

        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
