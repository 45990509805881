import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField
} from '@material-ui/core';

import LoadingButton from '../extComponents/LoadingButton';

import { fetchSettings, saveSettings } from '../api';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function Settings() {
    const [status, setStatus] = useState('Idle');
    const [content, setContent] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const handleChange = (e) => {
        setContent(e.target.value);
    };

    const handleSave = () => {
        setStatus('LOADING');
        saveSettings(
            content, 
            () => {
                setStatus('IDLE');
                dispatch({type: 'ADD_ALERT', alertId: 'SETTINGS_SAVE_SUCCESS'});
                history.replace('/manage/settings');
            },
            () => {
                dispatch({type: 'ADD_ALERT', alertId: 'SETTINGS_SAVE_FAIL'});
                history.replace('/manage/settings');
            }
        );
    };

    useEffect(() => {
        fetchSettings((res) => {
            setContent(res.data);
        });
    },
    []) // Only run once loaded

    return (
        <Box>
            <Grid container spacing={3} justify="space-between">
                <Grid item>
                    <Typography variant="h3" color="primary" gutterBottom>Settings</Typography>
                </Grid>
                <Grid item>
                    <LoadingButton variant="contained" color="primary" style={{margin: 10}} onClick={handleSave} status={status}>Save</LoadingButton>
                </Grid>
            </Grid>
            
            <TextField variant="outlined" fullWidth margin="normal" multiline rows={30} value={content} onChange={handleChange} />
        </Box>
    );
}