import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useTracker } from '../extComponents/useTracker';

import BlogPosts from './entryList';
import BlogDetail from './detailedEntry';

export default function Blog() {
    useTracker();

    const location = useLocation();
    if(matchPath(location.pathname, {path: "/blog/view/:id", exact: true})) {
        return <BlogDetail />;
    } else {
        return <BlogPosts />;
    }

}