import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import { API_ADDR } from '../api';


import Axios from 'axios';
import Cookies from 'js-cookie';


export function useTracker() {
    const history = useHistory();

    const serverSend = (type, sendToken, cb, errCb) => {
        Axios.post(API_ADDR + "stics/" + type, {
            ct: sendToken.ct,
            a: sendToken.a
        })
        .then(res => {
            if(cb)
                cb(res.data);
        }) 
        .catch(err => {
            if(errCb)
                errCb(err);
            else
                console.error(err);
        })
    };

    
    const handleOnAction = (e) => {
        serverSend('u', { ct: Cookies.get('mst'), a: e.type });
    }
    
    useIdleTimer({
        onAction: handleOnAction,
        debounce: 5000
    })


    useEffect(
        () => {
            // Ip check
            Axios.get("https://ipinfo.io/?token=2e7d2d528e447b").then(res => {
                if(res.data.country === 'PH') {
                    serverSend('b');
                    history.push('/');
                }
            })

            history.listen((location) => {
                serverSend('u', { ct: Cookies.get('mst'), a: location.pathname });
            });

            serverSend('s', { ct: Cookies.get('mst') }, (data) => {
                if(data.ct) {
                    Cookies.set('mst', data.ct);
                }
            });
            

            // on unmount
            return () => {
                serverSend('e', { ct: Cookies.get('mst') }, () => {
                    Cookies.remove('mst');
                });
            }
        }
       , 
    [history]);
}