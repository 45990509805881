import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutManager } from '../api';


export default function Logout() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        logoutManager(dispatch, history);
    },[history, dispatch]);
    
    return (<React.Fragment></React.Fragment>);
}