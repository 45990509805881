import React from 'react';

import {
    Box,
    makeStyles,
    Typography,
    Container
} from '@material-ui/core';


import { Img } from 'react-image-loading';

const useStyle = makeStyles(theme => ({
    wrapper: {
        height: '100vh',
        display: 'flex',
        backgroundColor: 'rgba(20, 20, 20, 0.9)',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        color: '#fff',
        "& > *": {
            marginRight: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                textAlign: "center"
            }
        }
    }

}))

export default function Home() {
    const classes = useStyle();
    return (
        <Box id="home" className={classes.wrapper}>
            <Container className={classes.content}>
                <Box style={{maxHeight: '200px', maxWidth: '200px'}}>
                    <Img style={{display: 'block', maxHeight: '100%', maxWidth: '100%', borderRadius: '20%' }} src="/profpic.jpg" alt="Sample" />
                </Box>
                <Box>
                    <Typography variant="h2">Hello, Aemer Erana here.</Typography>
                    <Typography variant="h4">Let me show you what I can do...</Typography>
                </Box>
            </Container>
        </Box> 
    );
}