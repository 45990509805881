import axios from 'axios';

export const API_ADDR = 'https://lucid-arch-272802.appspot.com/';
//export const API_ADDR = 'http://192.168.1.86:8080/';

export function loginManager(dispatch, history, user, pass) {
    dispatch({type: 'LOGIN_CHANGE_STATUS', payload: 'SUBMITTED'}); 

    if(user == null || pass == null) {
        dispatch({type: 'LOGIN_ERROR', payload: 'The user and/or pass must not be empty.'});
        return;
    }
    axios.post(API_ADDR + 'login', {
        type: 'LOGIN',
        user,
        pass
    })
    .then(res => {
        dispatch({type: 'LOGIN_CHANGE_STATUS', payload: 'SUCCESS'});
        localStorage.setItem("loginToken", res.data.token);
        history.replace("/manage");

    })
    .catch(err => {
        let payload = 'There was an error from the server!';
        if(err.response && err.response.data) {
            payload = err.response.data.msg;
        }
        dispatch({type: 'LOGIN_ERROR', payload });
    });
}


export function logoutManager(dispatch, history) {
    axios.post(API_ADDR + 'login', {
        type: 'LOGOUT',
        token: localStorage.getItem("loginToken")
    })
    .then(res => {
        localStorage.removeItem("loginToken");
        history.replace("/manage");
    });
}


export function saveEntryPost(dispatch, history, data) {
    // TODO:
    // - check data first
    axios.post(API_ADDR + 'blog', {
        type: 'ENTRY_SAVE',
        token: localStorage.getItem("loginToken"),
        entryId: data.entryId,
        title: data.title,
        entryData: data.entryData,
        date: data.date,
        category: data.category
    })
    .then(res => {
        dispatch({type: 'ADD_ALERT', alertId: 'BLOG_ENTRY_SAVE_SUCCESS'});
        history.replace("/manage/");
    })
    .catch(err => {
        dispatch({type: 'ADD_ALERT', alertId: 'BLOG_ENTRY_SAVE_FAIL'});
    });
}


export function fetchEntriesPost(callback, filter) {
    axios.post(API_ADDR + 'blog', {
        type: 'ENTRIES_FETCH',
        filter
    })
    .then(res => {
        callback(res.data.data);
    })
    .catch(err => {
        console.log(err);
    })
}

export function fetchEntriesPostManage(callback) {
    axios.post(API_ADDR + 'blog', {
        type: 'ENTRIES_FETCH_MANAGE',
        token: localStorage.getItem("loginToken")
    })
    .then(res => {
        callback(res.data.data);
    })
    .catch(err => {
        console.log(err);
    })
}

/* Fetch Entry By id
*/
export function fetchEntryById(id, callback) {
    axios.post(API_ADDR + 'blog', {
        type: 'ENTRY_FETCH',
        token: localStorage.getItem("loginToken"),
        id: id
    })
    .then(res => {
        callback(res.data.data);
    })
    .catch(err => {
        console.log(err.response);
    });
}

export function contactMe(data, onSuccessCb) {
    axios.post(API_ADDR + 'contact', {
        name: data.name,
        email: data.email,
        phone: data.phone,
        bttc: data.bttc,
        remarks: data.remarks,
    })
    .then(res => {
        onSuccessCb();
    })
    .catch(err => {
        console.log(err);
    });
}

/* Fetching Categories
* @brief: Functions for getting settings
*/
export function fetchBlogCategories(cb) {
    axios.post(API_ADDR + 'siteset/blogcat')
    .then(res => {
        cb(res.data.categories);
    })
    .catch(err => {
        console.log(err);
    })
}


/* Fetching Categories Editor
* @brief: Fetch all blog categories for editor
*/
export function fetchBlogCategoriesAll(cb) {
    axios.post(API_ADDR + 'siteset/manageblogcat', {
        token: localStorage.getItem("loginToken")
    })
    .then(res => {
        cb(res.data.categories);
    })
    .catch(err => {
        console.log(err);
    })
}

/* Fetch Settings
* @brief: Fetch all blog categories for editor
*/
export function fetchSettings(cb) {
    axios.post(API_ADDR + 'siteset/fetchset', {
        token: localStorage.getItem("loginToken")
    })
    .then(res => {
        cb(res.data);
    })
    .catch(err => {
        console.log(err);
    })
}

/* Save Settings
* @brief: Fetch all blog categories for editor
*/
export function saveSettings(content, cb, errorCb) {
    axios.post(API_ADDR + 'siteset/saveset', {
        token: localStorage.getItem("loginToken"),
        content
    })
    .then(res => {
        cb(res.data)
    })
    .catch(err => {
        errorCb(err);
        console.log(err);
    })
}