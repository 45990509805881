import React, { useState } from 'react';
import "./manage.css"

import {
    makeStyles,
    Toolbar,
    AppBar,
    IconButton,
    Typography
 } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import { matchPath, useLocation } from 'react-router-dom';

import SideBar from './sidebar';

import Blog from './Blog';
import Logout from './logout';
import Settings from './settings';



const useStyle = makeStyles((theme) => ({
    menuButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            marginRight: theme.spacing(2),
        }
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },

    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1)
    },
    
    toolbar : theme.mixins.toolbar,
    
}));


export default function Dashboard() {
    const classes = useStyle();
    const [open, setOpen] = useState(false);

    const openDrawer = () => {
        setOpen(true);
      };
    
    const closeDrawer = () => {
        setOpen(false);
    };

    // Determine the module path
    const location = useLocation();

    let module = <Blog />;
    if(matchPath(location.pathname, { path: "/manage/logout" })) {
        module = <Logout />;
    } else if(matchPath(location.pathname, { path: "/manage/settings" })) {
        module = <Settings />
    }


    return (
        <div className="manageApp">
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={openDrawer}
                    edge="start"
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6">Dashboard</Typography>
            </Toolbar>
            </AppBar>

            <SideBar 
             open={open} 
             openDrawer={openDrawer}
             closeDrawer={closeDrawer}
             classes={classes}
            />

            <main className={classes.content}>
                <div className={classes.toolbar} />
                { module }
            </main>
        </div>
    );
}