import React, { useEffect, useState } from 'react';

import {
    Box, 
    Card,
    CardContent,
    makeStyles,
    Typography,
    CardActionArea,
    Button,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { fetchEntriesPostManage } from '../../api';

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
     
    content: {
        width: 310,
        maxWidth: 310,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

export default function BlogPosts() {
    const [status, setStatus] = useState('LOADING');
    const [content, setContent] = useState([]);

    const classes = useStyle();
    const ContentComp = (data) => {
        return(
            <Card key={data.id} className={classes.content}>
                <CardActionArea component={Link} to={"/manage/blog/edit/" + data.id}>
                <CardContent>
                    <Typography noWrap variant="h6">{data.title}</Typography>

                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="overline">{moment(data.date).format("MMM DD, YYYY")}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="overline">{data.category}</Typography>
                        </Grid>
                    </Grid>
                    <Typography noWrap component="span">
                        {ReactHtmlParser(data.content, { transform: (node, index) => {
                                                if(node.name === 'p' && index >= 2) {
                                                    return null;
                                                }
                                            }})}
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card>
        );
    };


    useEffect(() => {
        const successCallback = (data) => {
            setStatus('DONE');
            setContent(data);
        };

        fetchEntriesPostManage(successCallback);
    }, []);


    return(
        <React.Fragment>
        <Box style={{display: 'flex', flexWrap: 'wrap'}}>
            <Box style={{flexGrow: 1}}>
                <Typography variant="h3" color="primary" gutterBottom>Blog Post Entries</Typography>
            </Box>
            <Box>
                <Button variant="contained" color="primary" component={Link} to='/manage/blog/add'>Add Entry</Button>
            </Box>
        </Box>
        <Box className={classes.root}>

            {
                status === 'LOADING' &&
                <Box style={{width: '100%', height: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center'}}><CircularProgress /></Box>
            }

            {
                content.map((elm) => {
                    return ContentComp(elm);
                })
            }

        </Box>
        </React.Fragment>
    );
}