// Alert Array Key
// {
//     id: 'SAMPLE_ID',
//     msg: 'Sample msg'
//     alertType: 'error' (default, success, warning, error, info)
// }
export const ALERT_MESSAGES = [
    // Entry Editor
    {id: 'BLOG_ENTRY_SAVE_SUCCESS', msg: 'Blog post successfully saved.', alertType: 'success'},
    {id: 'BLOG_ENTRY_SAVE_FAIL', msg: 'There was a problem saving your blog post.', alertType: 'error'},

    // Contact Me 
    {id: 'CONTACT_ME_SUCCESS', msg: 'Your request has been successfully sent.', alertType: 'success'},

    // Settings
    {id: 'SETTINGS_SAVE_SUCCESS', msg: 'The settings was saved successfully', alertType: 'success'},
    {id: 'SETTINGS_SAVE_FAIL', msg: 'There was a problem saving the settings', alertType: 'success'},

    {id: 'UNKNOWN', msg: 'An unknown error has occured.', alertType: 'error'}
];