import React from 'react';
import {
    Hidden,
    AppBar,
    Toolbar,
    Fab,
    Menu,
    MenuItem,
    Link as MULink,
    makeStyles
  } from "@material-ui/core";
  import MenuIcon from '@material-ui/icons/Menu';

  import { Link, scroller } from 'react-scroll';
  
const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: 'center',
        "& > *": {
            marginRight: theme.spacing(3)
        }
    },
    appBar: {
        backgroundColor: 'rgba(36, 36, 36, 0.7)'
    },
    menuButton: {
        position: 'fixed',
        right: theme.spacing(3),
        top: theme.spacing(3),
        zIndex: 1000
    },

    link: {
        "&:hover": {
            cursor: 'pointer'
        }
    },

    selectedLink: {
        fontWeight: 'bold',
    }
}));


export default function Header() {
    const classes = useStyle();
    const [anchor, setAnchor] = React.useState(null);

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchor(null);
    };

    const handleMenuClick = (dest) => {
        scroller.scrollTo(dest, {
            duration: 500,
            smooth: true,
        });

        handleClose();
    };

    const ScrollLink = (props) => {
        return (
            <MULink 
              color="inherit"
              component={Link} 
              activeClass={classes.selectedLink} 
              to={props.to}
              spy
              smooth
              duration={500}
              className={classes.link}
            >
                {props.children}
            </MULink>
        );
    };

    return(
    <React.Fragment>
        <Hidden smUp>
            <Fab color="primary" aria-label="menu" className={classes.menuButton} onClick={handleClick}>
                <MenuIcon />
            </Fab>
            <Menu 
              id="nav-menu"
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleClose}
              >
                <MenuItem onClick={() => handleMenuClick("home")}>Home</MenuItem>
                <MenuItem onClick={() => handleMenuClick("resume")}>Resume</MenuItem>
                <MenuItem onClick={() => handleMenuClick("portfolio")}>Portfolio</MenuItem>
                <MenuItem onClick={() => handleMenuClick("contact")}>Contact me</MenuItem>
            </Menu>
        </Hidden>
        <Hidden xsDown>
            <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.root}>

                <ScrollLink to="home">Home</ScrollLink>
                <ScrollLink to="resume">Resume</ScrollLink>
                <ScrollLink to="portfolio">Portfolio</ScrollLink>
                <ScrollLink to="contact">Contact me</ScrollLink>


            </Toolbar>
            </AppBar>
        </Hidden>
    </React.Fragment>
    );

}