import React from 'react';

import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Resume from './components/resume';
import Portfolio from './components/portfolio';

import Manage from './manage';
import Blog from './blog';


import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router-dom';
const history = createBrowserHistory();

function App() {
  const mainPage = (
    <>
      <div className="fxBg"><img alt="bg" src="/BG.jpg" /></div>
      <div className="App">
        <Header />
        <Home />
        <Resume />
        <Portfolio />
        <Footer />
      </div>
    </>);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Router history={history}>
      <Switch>
        <Route path="/manage" component={Manage} />
        <Route path="/blog" component={Blog} />
        <Route render={() => mainPage} />
      </Switch>
    </Router>
  );
}

export default App;
