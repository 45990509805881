import React, { useEffect, useState } from 'react';
import './blog.css'

import {
    Container,
    Box,
    Card,
    CardContent,
    Typography,
    IconButton,
    Avatar,
    makeStyles,
    CircularProgress
} from '@material-ui/core';
import { Link, useHistory, useLocation, matchPath } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { fetchEntryById } from '../api';

import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const useStyle = makeStyles(theme => ({
    header: {
        margin: theme.spacing(3),
        marginTop: 0,
        padding: theme.spacing(3),
        background: 'rgba(175, 175, 175, 0.1)'
    },

    cardEntry: {
        margin: theme.spacing(3),
        marginTop: 0,
        background: 'rgba(200, 200, 200, 0.1)',
    },

    navigationBox: {
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(3)
        }
    },
    
    posts: {
        [theme.breakpoints.down('xs')]: {
            order: 2
        }
    },
    
    categories: {
        [theme.breakpoints.down('xs')]: {
            order: 1,
            margin: theme.spacing(3),
            marginTop: 0,

        }
    },

    topIcons: {
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
        paddingBottom: 0
    }

}));

export default function Blog() {
    const classes = useStyle();
    const history = useHistory();

    const [status, setStatus] = useState('LOADING');
    const [content, setContent] = useState({
        title: '',
        date: null,
        category: '',
        data: ''
    })

    const handleBack = () => {
        history.goBack();
    };

    const location = useLocation();

    useEffect(() => {
        let match = matchPath(location.pathname, {path: '/blog/view/:id'});
        if(match) {
            const onSuccessOps = (data) => {
                setStatus('DONE');
                setContent({
                    title: data.title,
                    date: data.date,
                    category: data.category,
                    data: data.content
                });
            };
            fetchEntryById(match.params.id, onSuccessOps);
        }
    }, [location]);

    return(
        <div className="blogApp">
            <Container>

                <Box className={classes.topIcons}>
                    <IconButton aria-label="Home" onClick={handleBack}>
                        <ArrowBackIcon fontSize="large" />
                    </IconButton>
                    <IconButton component={Link} to="/blog" aria-label="Home">
                        <HomeIcon fontSize="large" />
                    </IconButton>
                    <Avatar alt="Aemer" component={Link} to="/" src="http://l.raviuz.com/profpic.jpg" style={{margin: 10}}></Avatar>
                </Box>

                {
                    status === 'LOADING' &&
                    <Box style={{width: '100%', height: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center'}}><CircularProgress /></Box>
                }

                { 
                    status === 'DONE' &&
                    <>
                    <Card variant="outlined" className={classes.header}>
                        <Typography variant="h3">{content.title}</Typography>
                        <Typography variant="overline" display="block">{moment(content.date).format("MMM DD, YYYY")}</Typography>
                        <Typography variant="overline" display="block">{content.category}</Typography>
                    </Card>

                    <Card variant="outlined" className={classes.cardEntry}>
                        <CardContent>
                            <Typography component="span" style={{padding: '10px'}}>
                                {ReactHtmlParser(content.data)}
                            </Typography>
                        </CardContent>
                    </Card>
                    </>
                }  

            </Container>
            
            <Box style={{textAlign: 'center'}}>
                    <Typography>Copyright &copy; Aemer Erana 2020</Typography>
            </Box>
        </div>
    );
}