import { ALERT_MESSAGES } from './alertMsgs';

const initData = {
    alertsList: [] // Objects array for alerts
}

const alertsHandler = (state = initData, action) => {
    switch(action.type) {

        /*************************************************************************** */
        case 'ADD_ALERT': {
            let alertsList = state.alertsList;
            
            let alert = ALERT_MESSAGES.find(obj => obj.id === action.alertId);
            alert = (alert === undefined) ? ALERT_MESSAGES.find(obj => obj.id === 'UNKNOWN') : alert;
            
            alertsList.push(alert);
            state = {
                ...state,
                alertsList
            }
            break;
        }

        /*************************************************************************** */
        case 'RM_ALERT': {
            state = {
                ...state,
                alertsList: state.alertsList.filter(val => val.id !== action.id)
            };
            break;
        }
        
        default: {}
    }
    return state;
};

export default alertsHandler;