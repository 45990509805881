import React from 'react';

 import Dashboard from './dashboard';
 import SignIn from './signIn';


 import { MuiPickersUtilsProvider } from '@material-ui/pickers';
 import MomentUtils from '@date-io/moment';


export default function Manage() {
    React.useEffect(
        () => {
            document.title = 'Aemer Erana - Manage'
        }, []
    );


    if(localStorage.getItem('loginToken')) {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Dashboard />
            </MuiPickersUtilsProvider>
        );
    } else {
        return (<SignIn />);
    }
}