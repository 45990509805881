const initData = {
    loginStatus: 'IDLE', // IDLE, ERROR, SUCCESS, SUBMITTED
    loginErrors: [] // Login related errors only.
};

const loginHandler = (state = initData, action) => {
    switch(action.type) {
        case 'LOGIN_CHANGE_STATUS': {
            state = {
                ...state,
                loginStatus: action.payload
            }
            break;
        }

        case 'LOGIN_ERROR': {
            let errList = [];
            errList.push(action.payload);

            state = {
                ...state,
                loginStatus: 'ERROR',
                loginErrors: errList
            };
            break;
        }

        default: {}

    }
    return state;
};

export default loginHandler;