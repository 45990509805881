import React from 'react';
import { Hidden,
    Drawer,
    SwipeableDrawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles } from '@material-ui/core';

// Icons
import ExitToApp from '@material-ui/icons/ExitToApp';
// import CalendarIcon from '@material-ui/icons/EventNote';
import LogbookIcon from '@material-ui/icons/MenuBook';
import SettingsIcon from '@material-ui/icons/Settings';

import { Link/*, useHistory*/ } from 'react-router-dom';

const drawerWidth = 240;

const useStyle = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: 0,
        },
      },
  
      drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down('sm')]: {
            width: 0,
        },
      },

      toolbar: theme.mixins.toolbar,
}));


const SideBar = (props) => {
    const classes = useStyle();
    const { open, openDrawer, closeDrawer } = props;
    // const history  = useHistory();

    const menuItems = () => {
        return (
            <List>
            {/* <ListItem button key='Schedule' component={Link} to="/manage/sched">
                <ListItemIcon><CalendarIcon /></ListItemIcon>
                <ListItemText primary='Schedule' />
            </ListItem> */}
            <ListItem button key='Blog' component={Link} to="/manage/blog">
                <ListItemIcon><LogbookIcon /></ListItemIcon>
                <ListItemText primary='Blog' />
            </ListItem>
            <ListItem button key='Settings' component={Link} to="/manage/settings">
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary='Settings' />
            </ListItem>
            <Divider />
            <ListItem button key='Logout' component={Link} to="/manage/logout">
                <ListItemIcon><ExitToApp /></ListItemIcon> 
                <ListItemText primary='Logout' />
            </ListItem>
            </List>
        );
    };

    return (
        <div>
        <Hidden smDown implementation="css">
            <SwipeableDrawer
                anchor="left"
                open={open}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >

            { menuItems() }
            </SwipeableDrawer>
        </Hidden>

        <Hidden>
            <Drawer
                variant="permanent"
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
            <div className={classes.toolbar} />
            <Divider />
            { menuItems() }
            </Drawer>
        </Hidden>
        </div>
    ); 
};

export default SideBar;