import React from 'react';
import {
    Button,
    CircularProgress,
    makeStyles,
    Box
} from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    buttonWrapper: {
        position: 'relative'
    },

    cirProg: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        '& > *': {
            transform: 'translate(-50%, -46%)'
        }
    }
}));

export default function LoadingButton(props) {
    const { status } = props; // STATUS: LOADING, DISABLED, IDLE
    const classes = useStyle();

    let disabled = false;
    if(status === "LOADING" || status === "DISABLED")
        disabled = true;
    
    return (
        <Box className={classes.buttonWrapper}>
            <Button {...props} disabled={disabled}>{props.children}</Button>
            { status === "LOADING" &&
            <Box className={classes.cirProg}>
                <Box><CircularProgress size={30} /></Box>
            </Box>
            }
        </Box>
    );
    
}