import React from 'react';

import { 
    Box,
    Container,
    Card,
    CardContent,
    Typography,
    makeStyles
} from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    wrapper: {
        minHeight: '100vh',
        background: 'rgba(30, 30, 30, 1.0)'
    },
    toolbar: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    contents: {
        color: '#fff'
    },
    porfolioWrapper: {

    },
    entryItem: {
        width: 375,
        color: "#fff",
        background: 'rgba(100, 100, 100, 0.8)',
        margin: theme.spacing(1)
    },
    header: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    viewMore: {
        margin: 'auto'
    }
}));


export default function Portfolio() {
    const classes = useStyle();
    return(
        <Box id="portfolio" className={classes.wrapper}>
            <Container className={classes.contents}>
                <div className={classes.toolbar}></div>
                <Typography variant="h3" className={classes.header}>Portfolio</Typography>

                <Box className={classes.porfolioWrapper} style={{display: 'flex', flexWrap: 'wrap'}}>
                    <Card className={classes.entryItem}>
                        <CardContent>
                            <Typography variant="h6">Pilot Record</Typography>
                            <Typography variant="overline">2017-Present</Typography>
                            <Typography paragraph>A time recording keeping application written in C++ utilizing a client-server architecture.</Typography>
                        </CardContent>
                    </Card>
                    <Card className={classes.entryItem}>
                        <CardContent>
                            <Typography variant="h6">KronosAirCrew</Typography>
                            <Typography variant="overline">2020-Present</Typography>
                            <Typography paragraph>A web base app written in React that will serve as a digital logbook and duty times record keeper for pilots.</Typography>
                        </CardContent>
                    </Card>

                    <Card className={classes.entryItem}>
                        <CardContent>
                            <Typography variant="h6">InfoResume</Typography>
                            <Typography variant="overline">2020-Present</Typography>
                            <Typography paragraph>A Single-Page Application (SPA) made with the React framework to showcase a person's profile.</Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Box style={{textAlign: 'center', padding: 10}}>
                    <Typography variant="overline">There are more in the works!</Typography>
                </Box>
            </Container>
        </Box>
    );
}