import React, { useState } from 'react';
import "./manage.css"

import {
    makeStyles,
    Card,
    CardContent,
    Typography,
    TextField
 } from '@material-ui/core';
 import { Alert } from '@material-ui/lab';
 import LoadingButton from '../extComponents/LoadingButton';

 import { loginManager } from '../api';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyle = makeStyles((theme) => ({
    loginBox: {
        width: 315,
        minHeight: 275
    }
}));


export default function SignIn() {
    const status = useSelector(state => state.loginHandler);
    const dispatch = useDispatch();
    const history = useHistory();

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    
    const classes = useStyle();

    const handleSubmit = (event) => {
        event.preventDefault();
        loginManager(dispatch, history, user, pass);
    };

    // Button status
    let btnStatus = status.loginStatus;
    if(status.loginStatus === 'SUBMITTED') {
        btnStatus = "LOADING";
    }

    return (
        <div className="manageApp loginContainer">
            <Card className={classes.loginBox}>
                <CardContent>
                    <Typography variant="h5">Login</Typography>
                    { status.loginStatus === 'ERROR' &&
                        status.loginErrors.map((val, key) => {
                            return (<Alert key={key} severity="error">{val}</Alert>);
                        })
                    }
                    <form onSubmit={handleSubmit}>
                        <TextField name="user" label="Username" variant="outlined" margin="normal" fullWidth value={user} onChange={e => setUser(e.target.value)} />
                        <TextField name="pass" type="password" label="Password" variant="outlined" margin="normal" fullWidth value={pass} onChange={e => setPass(e.target.value)} />
                        <LoadingButton status={btnStatus} type="submit" variant="contained" color="primary" fullWidth>Log In</LoadingButton>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}