import React, { useState } from 'react';
import {
    Box,
    makeStyles,
    Container,
    Typography,
    TextField,
    Grid
  } from "@material-ui/core";
import LoadingButton from '../extComponents/LoadingButton';
import { useDispatch } from 'react-redux';

import { contactMe } from '../api';
  
const useStyle = makeStyles((theme) => ({
    wrapper: {
        minHeight: '100vh',
        background: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(10px)'
    },

    contents: {
        minHeight: '100vh',
        position: 'relative',
        "& > *": {
            width: '100%',
        }
    },

    heading: {
        fontFamily: 'Cardo',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    spacer: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },

    footer: {
        width: '95%',
        position: 'absolute',
        bottom: 20,
        textAlign: "center",
    },

    toolbar: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.down("xs")]: {
            display: 'none'
        }
    },

}));


export default function Footer() {
    const classes = useStyle();
    const dispatch = useDispatch();

    const [status, setStatus] = useState('IDLE');
    const [requiredError, setReqError] = useState(false);
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        phone: '',
        bttc: '',
        remarks: ''
    });

    const onChange = (event) => {
        setReqError(false);
        setContactData({
            ...contactData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = () => {
        if(contactData.name === '' || contactData.email === '' ||
        contactData.remarks === '') {
            setReqError(true);
            return;
        }

        const successOps = () => {
            setStatus("SUCCESS");
            setContactData({
                name: '',
                email: '',
                phone: '',
                bttc: '',
                remarks: ''
            });
            dispatch({type: 'ADD_ALERT', alertId: 'CONTACT_ME_SUCCESS'});
        };

        setStatus("LOADING");
        contactMe(contactData, successOps);
    };

    return(
        <Box id="contact" className={classes.wrapper}>
            <Container className={classes.contents}>
                <div className={classes.toolbar} />
                <Box>
                    <Typography variant="h3" className={classes.heading}>Let's keep in touch...</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField variant="outlined" label="Name" name="name" value={contactData.name} error={requiredError} required={true} onChange={onChange} fullWidth/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField variant="outlined" label="Email" type="email" name="email" value={contactData.email} error={requiredError} required={true} onChange={onChange} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField variant="outlined" label="Phone" name="phone" value={contactData.phone} onChange={onChange} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField variant="outlined" label="Best Time to Contact" name="bttc" value={contactData.bttc} onChange={onChange} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" label="Comments" name="remarks" error={requiredError} required={true} value={contactData.remarks} onChange={onChange} fullWidth multiline rows={8} />
                        </Grid>
                        <Grid item xs={false} sm={9} className={classes.spacer}></Grid>
                        <Grid item xs={12} sm={3}>
                            <Box style={{ marginBottom: '50px' }}>
                                <LoadingButton variant="outlined" status={status} onClick={handleSubmit} fullWidth>Send</LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                    
                </Box>
                <Box className={classes.footer}>
                    <Typography>Copyright &copy; Aemer Erana 2020</Typography>
                </Box>
            </Container>
        </Box>
    );

}