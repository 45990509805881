import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation, matchPath } from 'react-router-dom';

import {
    Box, 
    makeStyles,
    Typography,
    Button,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import TextEditor from 'mui-rte';

import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { DatePicker } from '@material-ui/pickers';

import { saveEntryPost, fetchEntryById, fetchBlogCategoriesAll } from '../../api';

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },

    saveButton: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1)
        }
    },
    
    selectControl: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

export default function BlogEditor() {
    const [title, setTitle] = useState('');
    const [defaultVal, setDefaultVal] = useState(null);
    const [entryData, setEntryData] = useState(null);
    const [date, setDate] = useState(new Date());
    const [category, setCategory] = useState('');

    const [categories, setCategories] = useState([]);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    // After Loading function
    useEffect(() => {
        fetchBlogCategoriesAll((data) => {
            setCategories(data);
            
            let match = matchPath(location.pathname, {path: '/manage/blog/edit/:id'});
            if(match) {        
                const successOps = (data) => {
                    if(data.content === '' || data.content === undefined) {
                        return;
                    }
                    
                    const contetntHTML = convertFromHTML(data.content);
                    const contState = ContentState.createFromBlockArray(contetntHTML.contentBlocks, contetntHTML.entityMap);
                    setDefaultVal(JSON.stringify(convertToRaw(contState)));
                    setTitle(data.title);
                    setDate(data.date);
                    setCategory(data.category);
                };

                fetchEntryById(match.params.id, successOps);
            }
        });
    }, [location]);
    
    // Save handler
    const handleSave = () => {
        let match = matchPath(location.pathname, { path: '/manage/blog/edit/:id' });
        if(match) {
            // Edit Mode
            saveEntryPost(dispatch, history, {
                entryId: match.params.id,
                title,
                entryData,
                date,
                category
            });
        } else {
            // Add Mode
            saveEntryPost(dispatch, history, {
                title,
                entryData,
                date,
                category
            });
        }

    };

    // Change to inputs handler
    const handleChange = (state) => {
        const content = state.getCurrentContent();
        setEntryData(content.getPlainText() ? stateToHTML(content) : '');
    }

    // Change of input value handler
    const handleSelectChange = (event) => {
        setCategory(event.target.value);
    };

    const classes = useStyle();
    return(
        <Box>
            <Box className={classes.root}>
                <Box style={{flexGrow: '1'}}>
                    <Typography variant="h3" color="primary" gutterBottom>Blog Post Editor</Typography>
                </Box>
                <Box>
                    <Button variant="contained" color="primary" className={classes.saveButton} onClick={handleSave}>Save Entry</Button>
                </Box>
            </Box>

            <TextField 
              variant="outlined" 
              label="Title" 
              fullWidth
              name="Title"
              value={title} 
              onChange={e => setTitle(e.target.value)} 
              margin="normal" />
            
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <DatePicker 
                    value={date} 
                    onChange={setDate} 
                    format="MMM DD, YYYY"
                    autoOk
                    inputVariant="outlined"
                    fullWidth
                    margin="normal" />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.selectControl}>
                    <InputLabel id="selectLabelCategory">Category</InputLabel>
                    <Select
                      labelId="selectLabelCategory"
                      id="selectCategory"
                      label="Category"
                      onChange={handleSelectChange}
                      value={category}
                    >
                        {
                            categories.map((elm, indx) => {
                                if(indx === 0) {
                                    return (<MenuItem key={elm.title} value={elm.title}><em>{elm.title}</em></MenuItem>);
                                } else {
                                    return (<MenuItem key={elm.title} value={elm.title}>{elm.title}</MenuItem>);
                                }
                            })
                        }
                    </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TextEditor 
                defaultValue={defaultVal}
                label="Start typing..."
                controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "media", "numberList", "bulletList", "quote", "code"]}
                onChange={handleChange}
            />
        </Box>
    );
}