import React from 'react';

import { 
    Box,
    Container,
    Typography,
    makeStyles,
    Grid,
    Card,
    CardContent,
    ButtonBase,
    Paper
} from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    wrapper: {
        minHeight: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        color: '#fff'
    },

    header: {
        textShadow: '2px 2px #303030',
    },

    toolbar: theme.mixins.toolbar,

    contentWrapper: {
        paddingBottom: theme.spacing(5)
    },

    cardContent: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }
}));


export default function Resume() {
    const classes = useStyle();
    return(
        <Box id="resume" className={classes.wrapper}>
            <Container>
                <div className={classes.toolbar} />
                
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom className={classes.header}>
                            Qualifications
                        </Typography>

                        <Grid container spacing={3} className={classes.contentWrapper}>
                            <Grid item xs={12} sm={6} md={3}>
                            <Card className={classes.cardContent}>
                            <CardContent>
                                <Box style={{height: 128, display: 'flex', justifyContent: 'center', padding: '20px'}}><img src="pilotLogo.png" alt="Pilot Logo" /></Box>
                                <Typography variant="h5" gutterBottom>Multi-IFR rated Commercial Pilot</Typography>
                                <Typography paragraph>350 hrs. total hours from aircrafts ranging from C206 to B99A</Typography>
                            </CardContent>
                            </Card>
                            </Grid>
                            
                            
                            <Grid item xs={12} sm={6} md={3}>
                            <Card className={classes.cardContent}>
                            <CardContent>
                                <Box style={{height: 128, display: 'flex', justifyContent: 'center', padding: '20px'}}><img src="webprog.png" alt="Programming Logo" /></Box>
                                <Typography variant="h5" gutterBottom>Full Stack developer</Typography>
                                <Typography paragraph>Different types of languages ranging from C++ to JS. Frontend and backend. You name it, I'll learn it.</Typography>
                            </CardContent>
                            </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                            <Card className={classes.cardContent}>
                            <CardContent>
                                <Box style={{height: 128, display: 'flex', justifyContent: 'center', padding: '20px'}}><img src="network.png" alt="Cloud Service Logo" /></Box>
                                <Typography variant="h5" gutterBottom>IaaS Experience</Typography>
                                <Typography paragraph>Experiences from AWS, Microsoft Azure, and Google Cloud.</Typography>
                            </CardContent>
                            </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                            <Card className={classes.cardContent}>
                            <CardContent>
                                <Box style={{height: 128, display: 'flex', justifyContent: 'center', padding: '20px'}}><img src="computer.png" alt="OS Logo" /></Box>
                                <Typography variant="h5" gutterBottom>Multi OS Knowledge</Typography>
                                <Typography paragraph>Extensive knowledge in Linux/Unix, Windows and Mac OS.</Typography>
                            </CardContent>
                            </Card>
                            </Grid>

                        </Grid>

                    </Grid>
                    

                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom className={classes.header}>
                            Skills
                        </Typography>
                        <Grid container spacing={3} className={classes.contentWrapper}>
                            <Grid item xs={12} sm={6} md={3}>
                            <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>Self taught programmer</Typography>
                                <Typography paragraph>Since 2009, my programming journey has taken me from learning different varieties of languages. Starting from PHP, and making my way to C++ and still willing to learn new languages!</Typography>
                            </CardContent>
                            </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                            <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>Born for computers</Typography>
                                <Typography paragraph>Since my early teenage years, I have been greatly intrested in programming and been exposed to it's complexity and logical procedures.</Typography></CardContent>
                            </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                            <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>Willing to learn</Typography>
                                <Typography paragraph>If there are certain hurdles where it requires me to learn or master other skills, there will be no hesitation on learning it.</Typography>
                            </CardContent>
                            </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                            <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>Hungry for a challenge</Typography>
                                <Typography paragraph>Simply put that I strive harder to accomplish challenging projects.</Typography>
                            </CardContent>
                            </Card>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>

                        <Typography variant="h5" gutterBottom className={classes.header}>
                            Connect With Me
                        </Typography>
                        
                        <Grid container spacing={3} className={classes.contentWrapper}>
                            <Grid item>
                                <ButtonBase target="_blank" href="http://instagram.com/aemererana" component='a'>
                                    <Paper style={{backgroundColor: 'rgba(255,255,255,0.5)', padding: 10}}>
                                        <img  alt="IG Logo" src="/iglogo.png" />
                                    </Paper>
                                </ButtonBase>
                            </Grid>
                            
                            
                            <Grid item>
                                <ButtonBase component="a" href="mailto:aemer@erana.me">
                                    <Paper style={{backgroundColor: 'rgba(255,255,255,0.5)', padding: 10}}>
                                        <img  alt="email" src="/email.png" />
                                    </Paper>
                                </ButtonBase>
                            </Grid>

                            <Grid item>
                                <ButtonBase href="#resumefile" component='a'>
                                    <Paper style={{backgroundColor: 'rgba(255,255,255,0.5)', padding: 10}}>
                                        <img  alt="Resume" src="/resume.png" />
                                    </Paper>
                                </ButtonBase>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                
            </Container>
            <div className={classes.toolbar} />
        </Box>
    );
}