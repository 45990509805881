import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import BlogEditor from './EntryEditor';
import BlogPosts from './EntryComponent';

export default function Blog() {
    const location = useLocation();
    if(matchPath(location.pathname, {path: "/manage/blog/add"}) || matchPath(location.pathname, {path: "/manage/blog/edit/:id"})) {
        return <BlogEditor />;
    } else {
        return <BlogPosts />;
    }

}